import { ApplicationData, getManifestData } from './manifest.service';
import {PanelItems} from "../components/widget/ServicePanel/ServicePanel";
const applicationData: ApplicationData = getManifestData();

export const ChromeApi = {
    handleAction(dataType:string, action:PanelItems['action']) {
        if(applicationData.settings.isCrx) {
            (window as any).chrome.runtime.sendMessage({
                from: 'content',
                subject: action
            });
            return;
        }
        window.postMessage({ type: dataType, action: action }, '*');
    }
}