 const get = (key: string) => JSON.parse(localStorage.getItem(key) || 'null');
 const set = (key: string, newValue: unknown) => localStorage.setItem(key, JSON.stringify(newValue));
 const remove = (key: string) => localStorage.removeItem(key);
 const getWithCache = (key: string) => {
    const storageData = get(key);
    if (storageData && storageData.expirationDate && storageData.expirationDate < new Date().getTime()) {
        remove(key);
        return null;
      }
      return storageData && storageData.value;
 }
 const setWithCache = (key:string, value:any, time:number) =>{
    set(key,{value: value, expirationDate: time});
}
 
export const localStorageService = {
    get,
    set,
    remove,
    getWithCache,
    setWithCache
}