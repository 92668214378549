import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '../theme';
import { SearchBar } from './widget/SearchBar/SearchBar';
import { Panel } from './Panel';
import { Stack, SxProps } from '@mui/material';
import { ApplicationData, BackgroundImages, getManifestData, WidgetsInPanel } from '../services/manifest.service';
import { DateAndTime } from './widget/DateAndTime';
import { ServicePanel } from './widget/ServicePanel/ServicePanel';
import { Bullets } from './widget/Bullets';
import { PopularSites } from './widget/PopularSites/PopularSites';
import { Bookmarks } from './widget/Bookmarks/Bookmarks';
import { GlobalContext } from '../hooks/GlobalContext';
import { localStorageService } from '../services/storage.service';
import {LegalLinks} from "./widget/ServicePanel/LegalLinks";

export enum PanelSide {
  left = 'left',
  right = 'right'
}
export enum DefaultOption {
  search = 'search',
  date = 'date',
  news = 'news'
}

export enum StorageKeys {
  background = 'background',
  timeFormat = 'time-format',
  site = 'site',
  notes = 'notes',
  weather = 'weather',
  todo = 'todo',
  stocks = 'stocks',
  widgets = 'widgets',
  newsCategory = 'newsCategory'
}

const getDefaultBackground = (backgrounds: BackgroundImages[]) => {
  const defaultBackground = localStorageService.get(StorageKeys.background);
  const result = backgrounds.find(({ isDefault }) => isDefault);
  if (defaultBackground) return defaultBackground;
  else if (result) {
    localStorageService.set(StorageKeys.background, result);
    return result;
  }
};
export const Container: React.FC = () => {
  const { backgrounds, widgets, policies } = useContext(GlobalContext);
  const [currentBackground, setCurrentBackground] = useState<BackgroundImages>(getDefaultBackground(backgrounds));

  const onBulletClick = (currentBackground: BackgroundImages) => {
    setCurrentBackground(currentBackground);
    localStorageService.set(StorageKeys.background, currentBackground);
  };
  const shouldShowPopularSites = widgets.main.popularSites.shouldShow;
  const shouldShowBookmarks = widgets.main.bookmarks.shouldShow;

  const widgetsForLeftPanel = Object.keys(widgets.panel ?? {}).filter(
    widget => widgets.panel?.[widget as WidgetsInPanel]?.locationPanel === PanelSide.left
  );

  const widgetsForRightPanel = Object.keys(widgets.panel ?? {}).filter(
    widget => widgets.panel?.[widget as WidgetsInPanel]?.locationPanel === PanelSide.right
  );

  useEffect(() => {
    const applicationData: ApplicationData = getManifestData();

    document.title = applicationData.settings.pageTitle;
  }, []);

  const showLeftPanel = widgetsForLeftPanel.length !== 0;
  const showRightPanel = widgetsForRightPanel.length !== 0;
  const sites = widgets.main.search.sites;
  const selectedSite = sites.find(site => site.name === widgets.main.search.default);
  const is12HFormat = widgets.main.date.hour12;

  return (
    <ThemeProvider themeMode={currentBackground.themeMode}>
      <Stack
        sx={{
          ...container,
          backgroundImage: `url(${currentBackground.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          height: '100vh'
        }}
      >
        <Stack sx={main}>
          <ServicePanel />
          <Stack direction="row" sx={{ justifyContent: 'space-between', gap: '80px' }}>
            <Stack direction="row" gap="40px" sx={!widgetsForLeftPanel.length ? { pr: '255px' } : {}}>
              {showLeftPanel && <Panel widgetsName={widgetsForLeftPanel} />}
            </Stack>
            <Stack sx={{ width: 576, gap: '60px', mx: '0', justifyContent: 'center' }}>
              <DateAndTime is12HFormat={is12HFormat} />
              {selectedSite && sites && <SearchBar selectedSite={selectedSite} sites={sites} />}
              <Stack flexDirection="column" justifyContent="space-between" gap="25px">
                {shouldShowPopularSites && <PopularSites url={widgets.main.popularSites.url} hasExpirationDate={widgets.main.popularSites.hasExpirationDate}/>}
                {shouldShowBookmarks && <Bookmarks />}
              </Stack>
            </Stack>
            <Stack sx={{ width: '300px', position: 'relative' }}>
              {showRightPanel && <Panel widgetsName={widgetsForRightPanel} />}
            </Stack>
          </Stack>
          <Stack >
            <Bullets currentBackground={currentBackground} onBulletClick={onBulletClick} />
            <LegalLinks items={policies.items} location={policies.location} color={policies.color}/>
          </Stack>

        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

const main: SxProps = {
  pt: '35px',
  pb: '84px',
  px: '50px',
  flex: '1',
  gap: '80px',
  height: '100%'
};
const container: SxProps = {
  overflow: 'overlay'
};
