import React, { FC } from 'react';
import {Button, Stack} from "@mui/material";

interface LegalLinksProps {
  items:{ text: string; href: string; }[]
  location: string,
  color: string
}

export const LegalLinks: FC<LegalLinksProps> = ({items,location,color}) => { 
    const css = {
        display: 'inline-flex', 
        p: 0, 
        gap: '5px',  
        position: 'relative'
    }
    const flexDirection = location == 'right'? 'flex-end': 'flex-start'

    return (
        <Stack flexDirection={'row'} sx={{ ...css, ['float']: location,['color']: color,['justify-content']: flexDirection,['align-item']: flexDirection }}>

            {items?.map((item, index) => {
                if (index === items.length - 1) {
                    return (
                        <Button href={item.href} key={index}>{item.text}</Button>
                    );
                } else {
                    return (
                        <Button href={item.href} key={index}>{item.text} |</Button>
                    );
                }
            })}
        </Stack>
    );
};
