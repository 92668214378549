import React, { FC, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import CreateDialog from './CreateDialog';
import { BookmarksList } from './BookmarksList';
import { FoldersBookmarks } from './FoldersBookmarks';
import { BookmarksActionPanel } from './BookmarksActionPanel';
import { CustomDialog } from '../../CustomDialog';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export interface BookmarksProps {
  bookmarks: {
    title: string;
    url: string | null;
    id: string;
    children?: { title: string; url: string | null; id: string }[];
  }[];
}

export enum DialogLabels {
  CREATE_BOOKMARK = 'createBookmark',
  CREATE_FOLDER = 'createFolder',
  EDIT_BOOKMARK = 'editBookmark',
  EDIT_FOLDER = 'editFolder'
}

const MESSAGE_KEYS = {
  CREATE_BOOKMARK: 'create-bookmark-or-folder',
  DELETE_BOOKMARK_OR_FOLDER: 'delete-bookmark-or-folder',
  UPDATE_BOOKMARK: 'update-bookmark'
};

export const Bookmarks: FC = () => {
  const [isOpen, setOpen] = useState<string>('');
  const [isBookmarksDialogOpen, setIsBookmarksDialogOpen] = useState(false);
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [folderName, setFolderName] = useState<string>('');
  const [title, setName] = useState('');
  const [currentBookmarkId, setCurrentBookmarkId] = useState('');
  const [url, setUrl] = useState<string | null>(null);
  const [bookmarks, setBookmarks] = useState<BookmarksProps['bookmarks']>([]);
  const theme = useTheme();
  const { t } = useTranslation();

  const getBookmarksData = (event: MessageEvent) => {
    if (event.data.type && event.data.type === 'send-bookmarks') {
      setBookmarks(event.data.action?.[0].children);
    }
  };

  const BOOKMARK_MESSAGE_KEYS = {
    BOOKMARK_KEY: 'bookmarks-key',
    ACTION_TYPE: 'bookmarks'
  };

  useEffect(() => {
    window.addEventListener('message', getBookmarksData);
    window.postMessage({ type: BOOKMARK_MESSAGE_KEYS.BOOKMARK_KEY, action: BOOKMARK_MESSAGE_KEYS.ACTION_TYPE }, '*');
    return () => window.removeEventListener('message', getBookmarksData);
  }, []);

  const handleFolder = (title: string) => {
    setFolderName(title);
    setIsFolderOpen(true);
  };

  const handleCreateDialogOpen = (type: string) => {
    setOpen(type);
  };

  const handleOpenUpdateDialog = (bookmarkId: string) => {
    setCurrentBookmarkId(bookmarkId);
    const currentItem = bookmarks.find(({ id }) => id === bookmarkId);
    const currentChildrenItem = bookmarks
      .filter(({ children }) => children)
      .map(item => item?.children?.find(({ id }) => id === bookmarkId))
      .filter(item => item)[0];

    if (currentItem?.title) setName(currentItem?.title);
    if (currentChildrenItem?.title) setName(currentChildrenItem?.title);
    if (currentItem?.url) setUrl(currentItem?.url);
    if (currentChildrenItem?.url) setUrl(currentChildrenItem?.url);
    setOpen(currentItem?.children ? DialogLabels.EDIT_FOLDER : DialogLabels.EDIT_BOOKMARK);
  };

  const handleBookmarkUpdate = (values: { title: string; url: string | null }) => {
    window.postMessage({ type: MESSAGE_KEYS.UPDATE_BOOKMARK, action: { id: currentBookmarkId, values } }, '*');
    setOpen('');
    setName('');
    setUrl(null);
  };

  const handleCreate = (values: { title: string; url: string | null }) => {
    window.postMessage({ type: MESSAGE_KEYS.CREATE_BOOKMARK, action: values, folderName: folderName }, '*');
    setOpen('');
    setName('');
    setUrl(null);
  };

  const handleDeleteBookmarkOrFolder = (id: string) => {
    window.postMessage({ type: MESSAGE_KEYS.DELETE_BOOKMARK_OR_FOLDER, action: id }, '*');
  };

  const handleCreateDialogClose = () => {
    setOpen('');
    setName('');
    setUrl(null);
  };

  const handleCloseFolder = () => {
    setIsFolderOpen(false);
    setFolderName('');
  };

  const handleCloseBookmarksDialog = () => setIsBookmarksDialogOpen(false);
  const handleOpenBookmarksDialog = () => setIsBookmarksDialogOpen(true);

  const myFolderBookmarks: BookmarksProps['bookmarks'] | undefined = useMemo(
    () => bookmarks?.find(({ title }) => title === folderName)?.children,
    [folderName, bookmarks]
  );

  const initialBookmarks = bookmarks.slice(0, 5);

  return (
    <Stack>
      <BookmarksActionPanel handleCreateDialogOpen={handleCreateDialogOpen} />
      {bookmarks && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          <BookmarksList
            myBookmarks={initialBookmarks}
            handleFolder={handleFolder}
            handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
            handleOpenUpdateDialog={handleOpenUpdateDialog}
          />
          {bookmarks.length > 5 && (
            <IconButton
              sx={{ background: theme.customBackground.folder, borderRadius: '8px' }}
              onClick={handleOpenBookmarksDialog}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </Stack>
      )}
      <CreateDialog
        handleClose={handleCreateDialogClose}
        isOpen={isOpen !== ''}
        label={isOpen}
        handleCreate={handleCreate}
        title={title}
        url={url || ''}
        setName={setName}
        setUrl={setUrl}
        handleBookmarkUpdate={handleBookmarkUpdate}
      />
      <FoldersBookmarks
        bookmarks={myFolderBookmarks}
        folderName={folderName}
        isOpen={isFolderOpen}
        handleClose={handleCloseFolder}
        handleCreateDialogOpen={handleCreateDialogOpen}
        handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
        handleOpenUpdateDialog={handleOpenUpdateDialog}
      />
      <CustomDialog
        title={t('bookmarks:bookmarksHeader')}
        isOpen={isBookmarksDialogOpen}
        handleClose={handleCloseBookmarksDialog}
      >
        <BookmarksList
          myBookmarks={bookmarks}
          handleFolder={handleFolder}
          handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      </CustomDialog>
    </Stack>
  );
};
