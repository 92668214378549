import React, { useContext } from 'react';
import { Text } from '../../Text';
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Category } from '../../../services/manifest.service';
import { cloneDeep } from 'lodash';

interface NewsCategoryTabProps {
  selected?: string;
  categories?: Category[];
}

export const NewsCategoryTab: React.FC<NewsCategoryTabProps> = ({ selected, categories }) => {
  const { t } = useTranslation();
  const { widgets, saveWidgetToLocalStorage } = useContext(GlobalContext);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const currentKey = event.target.value;
    const result = categories?.find(category => category.key === currentKey);
    if (!result) return;

    const currentWidgets = cloneDeep(widgets);
    currentWidgets.panel!.news!.selectedCategory = result.key;
    saveWidgetToLocalStorage(currentWidgets);
  };

  const isLoading = !categories;

  if (isLoading) return <CircularProgress />;

  return (
    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
      <Text>{t('settings:news_categories')}</Text>
      <FormControl>
        <Select
          variant="standard"
          onChange={handleChange}
          value={selected}
          sx={{ '&:before': { borderBottom: 'unset' } }}
        >
          {categories?.map(category => (
            <MenuItem key={category.key} value={category.key}>
              <Stack direction="row" gap="5px">
                <Text>{category.name}</Text>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        <Stack />
      </FormControl>
    </Stack>
  );
};
