import { Stack, SxProps } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Text } from '../Text';
import { useTranslation } from 'react-i18next';
interface DateAndTimeProps {
  is12HFormat: boolean | undefined;
}
export const DateAndTime: React.FC<DateAndTimeProps> = ({ is12HFormat }) => {
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<string>('');

  const { i18n } = useTranslation();

  const handleClock = useCallback(() => {
    const dateNow = new Date();
    const dateConfig: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    };
    const timeConfig: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: is12HFormat
    };

    const currentDate = dateNow.toLocaleDateString(`${i18n.language}-US`, dateConfig);
    const currentTime = dateNow.toLocaleTimeString(`${i18n.language}-US`, timeConfig);
    setDate(currentDate);
    setTime(currentTime);
  }, [i18n.language, is12HFormat]);

  useEffect(() => {
    handleClock();
    const intervalId = setInterval(() => {
      handleClock();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [handleClock]);

  return (
    <Stack sx={container} gap="8px">
      <Text fontWeight="bold" sx={{ lineHeight: 1 }} size="xxxxl">
        {time}
      </Text>
      <Text sx={{ lineHeight: 1 }}>{date}</Text>
    </Stack>
  );
};

const container: SxProps = {
  alignItems: 'center'
};
