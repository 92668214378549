import { FormGroup, Stack, Switch } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Text } from '../../Text';
import { useTranslation } from 'react-i18next';
import { NewsCategoryTab } from './NewsCategoryTab';
import { useTheme } from '@mui/material/styles';
import { NewsWidget, WidgetsInPanel } from '../../../services/manifest.service';

interface NewsTabProps {
  data: NewsWidget;
}

export const NewsTab: React.FC<NewsTabProps> = ({ data }) => {
  const { setShouldPanelShow } = useContext(GlobalContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const { shouldShow, selectedCategory, categories, shouldEnableCategoryChange } = data;

  const handleShow = () => {
    setShouldPanelShow(WidgetsInPanel.newsFeed, shouldShow);
  };
  return (
    <FormGroup sx={{ p: 0 }}>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:news_widget')}</Text>
        <Stack direction="row" alignItems="center">
          <Switch checked={shouldShow} onChange={handleShow} />
        </Stack>
      </Stack>
      {shouldEnableCategoryChange && <NewsCategoryTab selected={selectedCategory} categories={categories} />}
    </FormGroup>
  );
};
