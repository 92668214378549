import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { MainWidgetsKeys, PanelWidgets, WidgetsInPanel } from '../../../services/manifest.service';
import { PanelSide } from '../../Container';
import { Text } from '../../Text';

interface PanelsTabProps {
  data: PanelWidgets;
}

export const PanelsTab: React.FC<PanelsTabProps> = ({ data }) => {
  const { saveWidgetToLocalStorage, widgets } = useContext(GlobalContext);

  const widgetsNotForPanel = Object.values(MainWidgetsKeys);
  const widgetsThatBelongToRightOrLeftPanel = Object.keys(data).filter(
    item => !widgetsNotForPanel.includes(item as MainWidgetsKeys)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, widgetName: WidgetsInPanel) => {
    const clonedWidgets = cloneDeep(widgets);
    if (clonedWidgets.panel) {
      clonedWidgets.panel[widgetName]!.locationPanel = event.target.value;
      saveWidgetToLocalStorage(clonedWidgets);
    }
  };
  return (
    <Stack>
      {widgetsThatBelongToRightOrLeftPanel.map((item, index) => {
        const widgetItem = data[item as WidgetsInPanel];
        return (
          <Stack key={index} direction="row" alignItems="center" gap="15px" justifyContent="space-between">
            <Text size="md">{widgetItem?.name}</Text>
            <Stack direction="row" alignItems="center">
              <FormControl>
                <RadioGroup
                  row
                  onChange={event => {
                    handleChange(event, item as WidgetsInPanel);
                  }}
                >
                  <FormControlLabel
                    checked={widgetItem?.locationPanel === PanelSide.left}
                    value={PanelSide.left}
                    control={<Radio />}
                    label="L"
                  />
                  <FormControlLabel
                    checked={widgetItem?.locationPanel === PanelSide.right}
                    value={PanelSide.right}
                    control={<Radio />}
                    label="R"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
