import React, { FC } from 'react';
import { Button, IconButton, List, ListItem, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';
import { PopularSitesData } from './PopularSites';

interface PopularSitesListProps {
  popularSites: PopularSitesData[];
  handleHover: (url: string | null) => void;
  handleDelete: (brandItem: string) => void;
  anchorEl: string | null;
}

export const PopularSitesList: FC<PopularSitesListProps> = ({ popularSites, handleHover, handleDelete, anchorEl }) => {
  const theme = useTheme();
  return (
    <List
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 47px)',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        gap: '10px',
        py: 0
      }}
    >
      {popularSites.map(({ brand, rurl: url, iurl: imgUrl }) => (
        <ListItem
          key={brand}
          sx={{ p: 0, width: 'auto', position: 'relative', color: 'primary.main' }}
          onMouseEnter={() => handleHover(brand)}
          onMouseLeave={() => handleHover(null)}
        >
          <IconButton
            onClick={() => handleDelete(brand)}
            sx={{
              opacity: anchorEl === brand ? 1 : 0,
              position: 'absolute',
              top: 0,
              right: '-50%',
              transform: 'translateX(-100%)',
              zIndex: 1,
              boxShadow: 1,
              backgroundColor: theme.customBackground.searchBar,
              backdropFilter: 'blur(20px)',
              p: '2px'
            }}
          >
            <RemoveIcon sx={{ fontSize: 12 }} />
          </IconButton>
          <Button href={url} title={brand}>
            <Stack
              textAlign="center"
              sx={{
                backgroundImage: `url(${imgUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: 47,
                height: 47,
                boxShadow: 1,
                borderRadius: '8px'
              }}
            />
          </Button>
        </ListItem>
      ))}
    </List>
  );
};
