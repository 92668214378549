import React, { FC, useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Text';
import { PopularSitesService } from '../../../services/popularSites.service';
import { localStorageService } from '../../../services/storage.service';
import { PopularSitesList } from './PopularSitesList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import { CustomDialog } from '../../CustomDialog';
import { httpService } from '../../../services/http.service';

export interface PopularSitesData {
  brand: string;
  rurl: string;
  iurl: string;
  impurl: string
}

export interface PopularSiteWidgets {
  url: string,
  hasExpirationDate: boolean
}

let impressionFired = false;
const storageKey = 'popularSites';

export const PopularSites: FC<PopularSiteWidgets> = ({url,hasExpirationDate}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<string | null>(null);
  const [isPopularSitesDialogOpen, setIsPopularSitesDialogOpen] = useState(false);
  const [popularSites, setPopularSites] = useState<PopularSitesData[]>([]);
  const theme = useTheme();

  // console.log("1 - popular sites - initialize ")

  const getPopularSitesData = async () => {
    // console.log("3 - popular sites - get data ")
    let localData = getDataFromLocalStorage();
    if(localData) {
      // console.log("4 - popular sites - use local storage ")
      sendImpressionPopularSite(localData);
      setPopularSites(localData)
      return ;
    }
    // console.log("5 - popular sites - fetch data ")
    const result = await PopularSitesService.getPopularSites(url);
    saveDataInLocalStorage(result.data);
    localData = result.data;
    setPopularSites(localData)
    sendImpressionPopularSite(localData);
    return localData;
  };

  const saveDataInLocalStorage = (data: any) => {
    if(hasExpirationDate){
      localStorageService.setWithCache(storageKey, data,expirationDate);
    }else{
      localStorageService.set(storageKey,data);
    }
  };

  const getDataFromLocalStorage = () => {
    if(hasExpirationDate)
      return localStorageService.getWithCache(storageKey);
    else
      return localStorageService.get(storageKey)
  };

  const expirationDate =  new Date().getTime() + (24 * 60 * 60 * 1000); // 1 day in milliseconds

  const handleHover = (url: string | null) => {
    setAnchorEl(url);
  };

  useEffect(() => {
    // console.log("2 - popular sites - use effect ")
    getPopularSitesData();
  }, []);


  useEffect(() => {
    // console.log("6 - popular sites - second use effect ")
    if (!popularSites || popularSites.length) return;
  }, [popularSites, popularSites.length]);

  const sendImpressionPopularSite = (popularSites:any[]) => {
    if(impressionFired)
      return;
    for(const i in popularSites){
      if(popularSites[i].impurl)
        httpService.get(popularSites[i].impurl, null);
    }
    impressionFired = true;
  };

  const handleDelete = (brandItem: string) => {
    const newPopularSitesArray: PopularSitesData[] = popularSites.filter(
        ({ brand }: { brand: string }) => brand !== brandItem
    );
    setPopularSites(newPopularSitesArray);
    saveDataInLocalStorage(newPopularSitesArray);
  };

  const handleClosePopularSitesDialog = () => setIsPopularSitesDialogOpen(false);
  const handleOpenPopularSitesDialog = () => setIsPopularSitesDialogOpen(true);

  const initialPopularSites = popularSites.slice(0, 5);

  return (
    <Stack>
      <Stack flexDirection="row" mb="20px">
        <Text size="lg" sx={{ mr: '16px' }}>
          {t('popularSites:popularSitesHeader')}
        </Text>
      </Stack>
      {popularSites && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          <PopularSitesList
            popularSites={initialPopularSites}
            handleDelete={handleDelete}
            handleHover={handleHover}
            anchorEl={anchorEl}
          />
          {popularSites.length > 5 && (
            <IconButton
              sx={{ background: theme.customBackground.folder, borderRadius: '8px' }}
              onClick={handleOpenPopularSitesDialog}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </Stack>
      )}
      <CustomDialog
        title={t('popularSites:popularSitesHeader')}
        isOpen={isPopularSitesDialogOpen}
        handleClose={handleClosePopularSitesDialog}
      >
        <PopularSitesList
          popularSites={popularSites}
          handleDelete={handleDelete}
          handleHover={handleHover}
          anchorEl={anchorEl}
        />
      </CustomDialog>
    </Stack>
  );
};
