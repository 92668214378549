import { Checkbox, Stack, SxProps } from '@mui/material';
import React from 'react';
import { Text } from '../../../Text';
import { Todo } from './TodoWidget';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

interface TodoProps {
  changeChecked: (todo: Todo[]) => void;
  removeTodo: (index: number) => void;
  todoList: Todo[];
}

export const TodoList: React.FC<TodoProps> = ({ changeChecked, removeTodo, todoList }) => {
  const handleChange = (index: number) => {
    const { isChecked } = todoList[index];
    todoList[index].isChecked = !isChecked;
    changeChecked(todoList);
  };

  return (
    <Stack sx={container}>
      {todoList.map((todo, index) => {
        const { isChecked } = todo;
        return (
          <Stack
            key={index}
            sx={{
              flexDirection: 'row',
              overflowX: 'hidden',
              alignItems: 'flex-start',
              '&:hover > .removeTodo': {
                opacity: 1
              }
            }}
          >
            <CancelSharpIcon
              className="removeTodo"
              onClick={() => {
                removeTodo(index);
              }}
              sx={cancelIconTodo}
            />
            <Checkbox
              size="medium"
              sx={{ p: '0' }}
              checked={isChecked}
              onChange={() => {
                handleChange(index);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Text sx={{ textDecoration: isChecked ? 'line-through' : 'none', wordBreak: 'break-word' }}>
              {todo.text}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

const container: SxProps = {
  '&:hover > .removeTodoList': {
    opacity: 1,
    '&:hover': {
      opacity: 1
    }
  }
};

const cancelIconTodo: SxProps = {
  position: 'absolute',
  right: '7px',
  cursor: 'pointer',
  color: 'common.white',
  transition: '.15s opacity ease-in',
  opacity: '0'
};
