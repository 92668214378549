import { Stack, SxProps } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../hooks/GlobalContext';
import { NewsFeed } from './widget/NewsFeed';
import { Notes } from './widget/Notes';
import { QuotesWidget } from './widget/QuotesWidget/QuotesWidget';
import { StockMarket } from './widget/StockMarketWidget/StockMarket';
import { TodoWidget } from './widget/WeatherWidget/Todo/TodoWidget';
import { Weather } from './widget/WeatherWidget/Weather';
import { SettingsWrapper } from './common/SettingsWrapper';
import { WidgetsInPanel } from '../services/manifest.service';

interface PanelProps {
  widgetsName: string[];
}

export const Panel: React.FC<PanelProps> = ({ widgetsName }) => {
  const { widgets } = useContext(GlobalContext);

  const temperatureUnit = widgets.panel?.weather?.unit;

  const symbols = widgets.panel?.stocks?.symbols || [];
  const fontFamily = widgets.panel?.quotes?.fontFamily;
  const pathWidget = (key: WidgetsInPanel) => {
    if (!key) return;

    const { shouldShow } = widgets.panel?.[key] ?? { shouldShow: false };
    const widgetsOption = {
      [WidgetsInPanel.notes]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={5}>
            <Notes />
          </SettingsWrapper>
        ),
      [WidgetsInPanel.weather]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={2}>
            <Weather temperatureUnit={temperatureUnit} />
          </SettingsWrapper>
        ),
      [WidgetsInPanel.todo]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={0}>
            <TodoWidget />
          </SettingsWrapper>
        ),
      [WidgetsInPanel.quotes]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={0}>
            <QuotesWidget fontFamily={fontFamily} />
          </SettingsWrapper>
        ),
      [WidgetsInPanel.stockMarket]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={3}>
            <StockMarket symbols={symbols} />
          </SettingsWrapper>
        ),
      [WidgetsInPanel.newsFeed]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={4}>
            <NewsFeed />
          </SettingsWrapper>
        )
    };
    return widgetsOption[key as WidgetsInPanel];
  };

  return (
    <Stack sx={container}>
      {widgetsName.map(widget => {
        const functionComponent = pathWidget(widget as WidgetsInPanel);
        if (!functionComponent) return null;
        return <Stack key={widget}>{functionComponent()}</Stack>;
      })}
    </Stack>
  );
};

const container: SxProps = {
  gap: '21px',
  position: 'relative',
  overflow: 'overlay',
  height: 'calc(100vh - 300px)',
  transition: 'color .3s ease',
  width: '300px'
};
