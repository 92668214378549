import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';

import { Text } from '../../Text';
import { SearchUrlBase } from '../../widget/SearchBar/SearchBar';
import GoogleIcon from '../../widget/SearchBar/assets/google.png';
import BingIcon from '../../widget/SearchBar/assets/bing.png';
import YahooIcon from '../../widget/SearchBar/assets/yahoo.png';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import { Site } from '../../../services/manifest.service';
import { cloneDeep } from 'lodash';
import { BiEventsService } from '../../../services/biEvent.service';
interface SearchTabProps {
  selectedSite: Site | undefined;
  sites: Site[] | undefined;
}
export const SearchTab: React.FC<SearchTabProps> = ({ selectedSite, sites }) => {
  const { widgets, saveWidgetToLocalStorage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const iconSites: Record<SearchUrlBase, string> = {
    [SearchUrlBase.google]: GoogleIcon,
    [SearchUrlBase.bing]: BingIcon,
    [SearchUrlBase.yahoo]: YahooIcon
  };
  const handleChange = (event: SelectChangeEvent<string>) => {
    if (!sites) return;
    const currentName = event.target.value;
    const currentSelectedSite = sites.find(site => site.name === currentName);
    if (!currentSelectedSite) return;

    BiEventsService.searchEngineChanged(widgets.main?.search.default, currentSelectedSite.name);

    const clonedWidgets = cloneDeep(widgets);
    clonedWidgets.main.search.default = currentSelectedSite.name;
    saveWidgetToLocalStorage(clonedWidgets);
  };

  if (!sites || !selectedSite) return <CircularProgress />;
  return (
    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
      <Text color={theme.customColors.setting}>{t('settings:site')}</Text>
      <FormControl>
        <Select
          variant="standard"
          onChange={handleChange}
          value={selectedSite.name}
          sx={{ '&:before': { borderBottom: 'unset' } }}
        >
          {sites.map(site => (
            <MenuItem key={site.name} value={site.name}>
              <Stack direction="row" gap="5px">
                <img src={iconSites[site.name as SearchUrlBase]} width={18} height={18} alt="icon search" />
                <Text color={theme.customColors.setting} sx={{ textTransform: 'uppercase' }}>
                  {site.name}
                </Text>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        <Stack />
      </FormControl>
    </Stack>
  );
};
