import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Switch } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Text } from '../../Text';
import { useTranslation } from 'react-i18next';
import { SearchTab } from './SearchTab';
import { useTheme } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import { WidgetsInPanel, MainWidgetsKeys } from '../../../services/manifest.service';

export interface PreferencesProps {
  is12HFormat: boolean | undefined;
}
export const Preferences: React.FC<PreferencesProps> = ({ is12HFormat }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { setShouldPanelShow, setShouldMainShow, widgets, saveWidgetToLocalStorage } = useContext(GlobalContext);

  const showToggleMainItems = [
    {
      key: 'bookmarks',
      text: 'show_bookmarks'
    },
    {
      key: 'popularSites',
      text: 'show_popular_sites'
    }
  ];
  const showTogglePanelItems = [];
  if(widgets.panel?.['todo' as WidgetsInPanel])
    showTogglePanelItems.push(
        {key: 'todo',
        text: 'show_todo'
    });
  if(widgets.panel?.['quotes' as WidgetsInPanel])
    showTogglePanelItems.push({
          key: 'quotes',
          text: 'show_quotes'
    });

  const sites = widgets.main.search.sites;
  const selectedSite = sites.find(site => site.name === widgets.main.search.default);

  const handleFormat = (event: SelectChangeEvent<string>) => {
    const result = event.target.value === '12';
    const currentWidgets = cloneDeep(widgets);
    currentWidgets.main.date.hour12 = result;
    saveWidgetToLocalStorage(currentWidgets);
  };
  return (
    <Stack sx={{ p: 0 }}>
      {showToggleMainItems.map(item => (
        <Stack key={item.key} direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
          <Text color={theme.customColors.setting}> {t(`settings:${item.text}`)} </Text>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={widgets.main[item.key as MainWidgetsKeys].shouldShow}
              onChange={() => {
                setShouldMainShow(item.key as MainWidgetsKeys, widgets.main[item.key as MainWidgetsKeys].shouldShow);
              }}
            />
          </Stack>
        </Stack>
      ))}
      {showTogglePanelItems.map(item => (
        <Stack key={item.key} direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
          <Text color={theme.customColors.setting}> {t(`settings:${item.text}`)} </Text>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={widgets.panel?.[item.key as WidgetsInPanel]?.shouldShow}
              onChange={() => {
                setShouldPanelShow(item.key as WidgetsInPanel, widgets.panel?.[item.key as WidgetsInPanel]?.shouldShow);
              }}
            />
          </Stack>
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
        <Text color={theme.customColors.setting}>{t('time_format', { ns: 'settings' })}</Text>
        <FormControl>
          <Select
            variant="standard"
            onChange={handleFormat}
            value={is12HFormat ? '12' : '24'}
            sx={{ '&:before': { borderBottom: 'unset' } }}
            inputProps={{
              name: 'time_format',
              id: 'uncontrolled-native'
            }}
          >
            <MenuItem value="24">
              <Text color={theme.customColors.setting}>24-Hour</Text>
            </MenuItem>
            <MenuItem value="12">
              <Text color={theme.customColors.setting}>12-Hour</Text>
            </MenuItem>
          </Select>
          <Stack />
        </FormControl>
      </Stack>
      <SearchTab sites={sites} selectedSite={selectedSite} />
    </Stack>
  );
};
