import { Button, Stack, SxProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { TodoList } from './TodoList';
import { useTranslation } from 'react-i18next';
import { localStorageService } from '../../../../services/storage.service';
import { StorageKeys } from '../../../Container';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Text } from '../../../Text';

export interface Todo {
  text: string;
  isChecked: boolean;
}
export const TodoWidget: React.FC = () => {
  const [todoList, setTodoList] = useState<Todo[]>([]);
  const [value, setValue] = useState<string>('');
  const theme = useTheme();
  const [shouldShowButton, setShouldShowButton] = useState<boolean>(true);
  const { t } = useTranslation();

  const removeTodo = (currentIndex: number) => {
    const tempTodoList = todoList.filter((_, index) => index !== currentIndex);
    setTodoList(tempTodoList);
    localStorageService.set(StorageKeys.todo, tempTodoList);
  };

  const changeTodoList = (value: string) => {
    const currentTodos = [...todoList];
    currentTodos.push({ text: value, isChecked: false });
    setTodoList(currentTodos);

    localStorageService.set(StorageKeys.todo, currentTodos);
  };
  const changeChecked = (todo: Todo[]) => {
    setTodoList([...todo]);
    localStorageService.set(StorageKeys.todo, todo);
  };
  const addTodo = (value: string) => {
    changeTodoList(value);
  };
  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const saveNewTodo = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!value) return;
    addTodo(value);
    setValue('');
    setShouldShowButton(!shouldShowButton);
  };

  const toggleBetweenInputAndButton = () => {
    setShouldShowButton(!setShouldShowButton);
  };

  useEffect(() => {
    const todoStorage = localStorageService.get(StorageKeys.todo);
    if (!todoStorage?.length || todoList.length) return;
    setTodoList(todoStorage);
  }, [todoList.length]);
  const result = t('todo:add_todo');
  return (
    <Stack gap="15px" sx={{ background: theme.palette.background.default, borderRadius: '10px', ...todoStyle }}>
      <Stack sx={{ p: 0 }}>
        <TodoList
          changeChecked={(todo: Todo[]) => {
            changeChecked(todo);
          }}
          removeTodo={removeTodo}
          todoList={todoList}
        />
      </Stack>

      {!shouldShowButton && (
        <form onSubmit={saveNewTodo}>
          <TextField
            placeholder={result}
            type="text"
            sx={textFiled}
            name="todo"
            value={value}
            onChange={handleValue}
            variant="standard"
          />
        </form>
      )}
      {shouldShowButton && (
        <Button onClick={toggleBetweenInputAndButton} sx={{ justifyContent: 'flex-start', gap: '7px' }}>
          <AddCircleOutlineRoundedIcon style={{ color: theme.palette.text.primary }} />
          <Text textTransform="capitalize">{t('todo:add_todo')}</Text>
        </Button>
      )}
    </Stack>
  );
};

const todoStyle: SxProps = {
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  filter: 'drop-shadow(-2px 0px 10px rgba(203, 199, 199, 0.07))',
  backdropFilter: 'blur(20px)',
  padding: '20px 17px'
};
const textFiled: SxProps = {
  width: '100%',
  padding: 0
};
