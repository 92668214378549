import React, { createContext, useEffect, useState } from 'react';
import { StorageKeys } from '../components/Container';
import { Data } from '../components/widget/StockMarketWidget/StockMarket';
import {
  ApplicationData,
  BackgroundImages,
  getManifestData,
  MainWidgetsKeys,
  Policies,
  Widgets,
  WidgetsInPanel
} from '../services/manifest.service';
import { localStorageService } from '../services/storage.service';
import _, { cloneDeep } from 'lodash';
import { WidgetName } from '../components/settings/components/WeatherTab';
import { BiEventsService } from '../services/biEvent.service';

const defaultMainSettings = {
  bookmarks: {
    shouldShow: true,
    name: 'Bookmarks'
  },
  popularSites: {
    shouldShow: true,
    name: 'PopularSite',
    url: '/tiles.json',
    hasExpirationDate: true
  },
  search: {
    shouldShow: true,
    name: 'Search panel',
    sites: [
      {
        name: 'google',
        icon: './assets/google.png',
        url: 'https://www.google.com/search?q='
      },
      {
        name: 'bing',
        icon: './assets/bing.png',
        url: 'https://www.bing.com/search?q='
      },
      {
        name: 'yahoo',
        icon: './assets/yahoo.png',
        url: 'https://search.yahoo.com/search?p='
      }
    ],
    default: 'bing'
  },
  date: {
    name: 'Date and Time',
    shouldShow: true,
    hour12: true
  }
};

const defaultPolicies = {
  items:[

  ],
  location: 'left',
  color: 'black'
}

interface IGlobalState {
  widgets: Widgets;
  setWidgets: React.Dispatch<React.SetStateAction<Widgets>>;
  stockMarketData: Data[] | never[];
  setStockMarketData: React.Dispatch<React.SetStateAction<Data[] | never[]>>;
  setIs12HFormat: React.Dispatch<React.SetStateAction<boolean>>;
  is12HFormat: boolean;
  backgrounds: BackgroundImages[];
  notes: string[];
  openSettingModal: boolean;
  setNotes: React.Dispatch<React.SetStateAction<string[]>>;

  setShouldPanelShow: (widgetName: WidgetsInPanel, shouldShow: boolean | undefined) => void;
  setShouldMainShow: (widgetName: MainWidgetsKeys, shouldShow: boolean | undefined) => void;
  findSpecificWidgetIndex: (widgetName: WidgetName) => number | undefined;
  settingsTabs: number;
  setSettingsTabs: React.Dispatch<React.SetStateAction<number>>;
  setOpenSettingModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveWidgetToLocalStorage: (value: Widgets) => void;
  policies: Policies
}

const initialState: IGlobalState = {
  widgets: { main: defaultMainSettings },
  setWidgets: () => {},
  stockMarketData: [],
  setStockMarketData: () => {},
  setIs12HFormat: () => {},
  is12HFormat: false,
  backgrounds: [],
  notes: [],
  setNotes: () => {},
  setSettingsTabs: () => {},
  settingsTabs: 0,
  setOpenSettingModal: () => {},
  openSettingModal: false,
  setShouldPanelShow: () => {},
  setShouldMainShow: () => {},
  findSpecificWidgetIndex: () => undefined,
  saveWidgetToLocalStorage: () => {},
  policies: defaultPolicies
};
export const GlobalContext = createContext<IGlobalState>(initialState);

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const applicationData: ApplicationData = getManifestData();
  const [widgets, setWidgets] = useState<Widgets>(applicationData.settings.widgets);
  const [stockMarketData, setStockMarketData] = useState<Data[] | never[]>([]);
  const [notes, setNotes] = useState<string[]>([]);
  const [settingsTabs, setSettingsTabs] = React.useState(0);
  const backgrounds: BackgroundImages[] = applicationData.settings.backgroundImages;
  const [openSettingModal, setOpenSettingModal] = useState<boolean>(false);
  const policies: Policies = applicationData.settings.policies? applicationData.settings.policies:defaultPolicies;

  const setShouldPanelShow = (widgetName: WidgetsInPanel, shouldShow: boolean | undefined) => {
    if (shouldShow === undefined || !widgets) return;
    const currentWidgets = cloneDeep(widgets);
    //@ts-ignore
    currentWidgets.panel[widgetName].shouldShow = !shouldShow;
    BiEventsService.widgetMode(!shouldShow, widgetName);
    saveWidgetToLocalStorage(currentWidgets);
  };
  const setShouldMainShow = (widgetName: MainWidgetsKeys, shouldShow: boolean | undefined) => {
    if (shouldShow === undefined || !widgets) return;
    const currentWidgets = cloneDeep(widgets);
    currentWidgets.main[widgetName].shouldShow = !shouldShow;
    BiEventsService.widgetMode(!shouldShow, widgetName);
    saveWidgetToLocalStorage(currentWidgets);
  };

  const saveWidgetToLocalStorage = (value: Widgets) => {
    localStorageService.set(StorageKeys.widgets, value);
    setWidgets({ ...value });
  };

  useEffect(() => {
    const widgetsFromLocalStorage: Widgets = localStorageService.get('widgets');
    if (!widgetsFromLocalStorage) return;
    const currentWidgets = _.merge(widgets, widgetsFromLocalStorage);
    setWidgets(currentWidgets);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        ...initialState,

        saveWidgetToLocalStorage,
        widgets,
        setShouldPanelShow,
        setShouldMainShow,
        setWidgets,
        stockMarketData,
        setStockMarketData,
        notes,
        setNotes,
        backgrounds,
        setSettingsTabs,
        settingsTabs,
        setOpenSettingModal,
        openSettingModal,
        policies
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
