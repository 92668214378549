import { Unit } from 'openweathermap-ts/dist/types';
import { manifest } from '../manifest';

export interface ApplicationData {
  settings: Settings;
}

interface SearchWidget extends Widget {
  sites: Site[];
  default: string;
}

interface DateWidget extends Widget {
  hour12: boolean;
}
interface WeatherWidget extends PanelWidget {
  unit: Unit;
}

interface QuotesWidget extends PanelWidget {
  fontFamily?: string;
}

export interface NewsWidget extends PanelWidget {
  categories: Category[];
  selectedCategory: string;
  shouldEnableCategoryChange?: boolean;
}

interface StocksWidget extends PanelWidget {
  symbols: string[];
}

export enum WidgetsInPanel {
  notes = 'notes',
  weather = 'weather',
  todo = 'todo',
  quotes = 'quotes',
  stockMarket = 'stocks',
  newsFeed = 'news'
}

export enum MainWidgetsKeys {
  search = 'search',
  date = 'date',
  bookmarks = 'bookmarks',
  popularSites = 'popularSites'
}

export type AllWidgets = WidgetsInPanel | MainWidgetsKeys;

export interface PanelWidgets {
  [WidgetsInPanel.weather]?: WeatherWidget;
  [WidgetsInPanel.quotes]?: QuotesWidget;
  [WidgetsInPanel.newsFeed]?: NewsWidget;
  [WidgetsInPanel.stockMarket]?: StocksWidget;
  [WidgetsInPanel.notes]?: PanelWidget;
  [WidgetsInPanel.todo]?: PanelWidget;
}

export interface MainWidgets {
  [MainWidgetsKeys.search]: SearchWidget;
  [MainWidgetsKeys.date]: DateWidget;
  [MainWidgetsKeys.popularSites]: PopularSites;
  [MainWidgetsKeys.bookmarks]: Widget;
}

export interface Widgets {
  panel?: PanelWidgets;
  main: {
    search: SearchWidget;
    date: DateWidget;
    popularSites: PopularSites;
    bookmarks: Widget;
  };
}

export interface Settings {
  widgets: Widgets;
  backgroundImages: BackgroundImages[];
  language: string;
  baseURL?: string;
  pageTitle: string;
  policies?: Policies;
  isCrx?: boolean
}

interface Widget {
  name: string;
  shouldShow: boolean;
}
export interface PanelWidget extends Widget {
  locationPanel: string;
}

export interface PopularSites extends Widget {
  url: string,
  hasExpirationDate: boolean
}
export interface Category {
  key: string;
  name: string;
}

export interface Site {
  name: string;
  icon?: string;
  url: string;
}
export interface BackgroundImages {
  url: string;
  themeMode: string;
  isDefault: boolean;
}

export interface Policies {
  items: { text: string; href: string; }[];
  location: string;
  color: string
}
const getManifestData = () => manifest;

export { getManifestData };
