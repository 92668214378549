import { Button, Stack, Switch, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../../../hooks/GlobalContext';
import { StokeMarketService } from '../../../../services/stockMarket.service';
import { Text } from '../../../Text';
import { Data } from '../../../widget/StockMarketWidget/StockMarket';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as AddCircle } from './assets/add_circle.svg';
import { cloneDeep } from 'lodash';
import { WidgetsInPanel } from '../../../../services/manifest.service';
interface StockMarketTabProps {
  symbols: string[];
}

export const StockMarketTab: React.FC<StockMarketTabProps> = ({ symbols }) => {
  const theme = useTheme();
  const { stockMarketData, setStockMarketData, setShouldPanelShow, widgets, saveWidgetToLocalStorage } =
    useContext(GlobalContext);
  const [newSymbol, setNewSymbol] = useState<string>('');
  const shouldShow = widgets.panel?.stocks?.shouldShow;
  const [showInput, setShowInput] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleStockMarketBySymbol = (index: number) => {
    const currentStockData = [...stockMarketData];
    currentStockData[index].isShow = !currentStockData[index].isShow;
    setStockMarketData(currentStockData);
  };
  const handleShow = () => {
    setShouldPanelShow(WidgetsInPanel.stockMarket, shouldShow);
  };
  const removeSymbol = (currentIndex: number, currentSymbol: string) => {
    const currentSymbols = symbols.filter(symbol => symbol !== currentSymbol);
    const currentStockMarketData = stockMarketData.filter((_, index) => currentIndex !== index);

    const clonedWidgets = cloneDeep(widgets);
    widgets.panel!.stocks!.symbols = currentSymbols;
    saveWidgetToLocalStorage(clonedWidgets);

    setStockMarketData(currentStockMarketData);
  };
  const addNewSymbol = async () => {
    setShowInput(prevShowInput => !prevShowInput);

    if (!newSymbol || !showInput) return;

    const clonedWidgets = cloneDeep(widgets);
    const symbols = widgets.panel!.stocks!.symbols;

    widgets.panel!.stocks!.symbols = [...symbols, newSymbol];
    saveWidgetToLocalStorage(clonedWidgets);

    const currentStockData = [...stockMarketData];
    const [result] = await StokeMarketService.get([newSymbol]);
    if (!result) return;
    currentStockData.push(result as unknown as Data);
    setStockMarketData(currentStockData);
    setNewSymbol('');
  };

  return (
    <Stack>
      <Stack gap="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color={theme.customColors.setting}>{t('settings:show_stock')}</Text>
          <Stack direction="row" alignItems="center">
            <Switch checked={shouldShow} onChange={handleShow} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="text" sx={{ textTransform: 'capitalize', p: 0, paddingBlock: '5px' }} onClick={addNewSymbol}>
            <Stack direction="row" gap="10px" alignItems="center">
              <AddCircle fill={theme.customColors.setting} />
              <Text color={theme.customColors.setting}>{t('settings:add')}</Text>
            </Stack>
          </Button>
          {showInput && (
            <TextField
              value={newSymbol}
              variant="outlined"
              placeholder="Add new symbol"
              sx={{
                alignItems: 'center',
                width: '140px',
                height: '30px',
                fontSize: theme.customComponents.text.size.xs,
                p: 0,
                '& input::placeholder': {
                  fontSize: '12px',
                  textAlign: 'center'
                }
              }}
              inputProps={{
                style: {
                  height: '30px',
                  padding: '0'
                }
              }}
              onChange={event => setNewSymbol(event.target.value)}
            />
          )}
        </Stack>
        <Stack>
          <Text color={theme.customColors.setting}>Show Symbol</Text>
          {stockMarketData.map((item, index) => (
            <Stack key={index} direction="row" alignItems="center" gap="15px" justifyContent="space-between">
              <Text color={theme.customColors.setting} size="md">
                {item.name}
              </Text>
              <Stack direction="row" alignItems="center">
                <Switch
                  onChange={() => {
                    handleStockMarketBySymbol(index);
                  }}
                  checked={item.isShow}
                />
                <Button
                  variant="text"
                  sx={{ textTransform: 'capitalize', color: theme.customColors.setting }}
                  onClick={() => {
                    removeSymbol(index, item.symbol);
                  }}
                >
                  {t('settings:delete')}
                </Button>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
