import { httpService } from './http.service';

const getPopularSites = async (url: string) => {
  const popularSitesUrl = url !== ''? url: '/tiles.json';
  const response = await httpService.get(popularSitesUrl, null);
  return response;
}

export const PopularSitesService = {
  getPopularSites
};
