import { httpService } from './http.service';

const searchEngineChanged = (from: string | undefined, to: string) => {
  if (!from) return;
  httpService.get(`/search/event/enginechanged?from=${from}&to=${to}`, null);
};

const widgetMode = (shouldShow: boolean, widgetName: string) => {
  const activeWidget = shouldShow ? 1 : 0;
  httpService.get(`/search/event/widgetstatus?name=${widgetName}&active=${activeWidget}`, null);
};

export const BiEventsService = {
  searchEngineChanged,
  widgetMode
};
