import { Button, CircularProgress, Stack, SxProps } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { newsService } from '../../services/news.service';
import { Text } from '../Text';
import { useTheme } from '@mui/material/styles';
import { ToggleShowButton } from '../common/ToggleShowButton';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../hooks/GlobalContext';

interface ImageData {
  thumbnail: {
    contentUrl: string;
  };
}

interface News {
  isBreakingNews: boolean;
  name: string;
  url: string;
  image?: ImageData;
}

const DEFAULT_ITEMS_AMOUNT_TO_SHOW = 3;
export const NewsFeed: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [news, setNews] = useState<News[] | null>(null);
  const [amountOfItemsToShow, setAmountOfItemsToShow] = useState<number>(DEFAULT_ITEMS_AMOUNT_TO_SHOW);
  const theme = useTheme();
  const { widgets } = useContext(GlobalContext);
  const selected = widgets.panel?.news?.selectedCategory;

  const getData = async () => {
    try {
      const result = await newsService.get(selected);
      setNews(result.value);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selected]);
  if (isLoading) return <CircularProgress color="secondary" />;
  if (!news) return <Stack>{t('common:no_have_results')}</Stack>;
  return (
    <Stack sx={{ ...newsStyle, background: theme.palette.background.default }}>
      <Text weight="bold" sx={{ mt: '30px', mb: '15px', ml: '44px' }}>
        {t('newsFeed:top_stories')}
      </Text>

      {news.slice(0, amountOfItemsToShow).map((item, index) => (
        <Button
          key={index}
          variant="text"
          href={item.url}
          sx={{ display: 'flex', flexDirection: 'row', mb: '20px', justifyContent: 'space-between' }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text sx={{ mr: '6px', textAlign: 'left' }}>{item.name}</Text>
          </Stack>
          {item.image && (
            <Stack sx={{ minWidth: '38px' }}>
              <img
                src={item.image?.thumbnail.contentUrl}
                alt=""
                width={38}
                height={38}
                style={{ borderRadius: '10px' }}
              />
            </Stack>
          )}
        </Button>
      ))}
      <ToggleShowButton
        changeLength={setAmountOfItemsToShow}
        amountOfItemsToShow={amountOfItemsToShow}
        item={news}
        DEFAULT_ITEMS_AMOUNT_TO_SHOW={DEFAULT_ITEMS_AMOUNT_TO_SHOW}
        textStyle={{ textAlign: 'center', mb: '21px' }}
      />
    </Stack>
  );
};

const newsStyle: SxProps = {
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  paddingInline: '22px'
};
