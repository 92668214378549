import { ApplicationData } from './services/manifest.service';

export const manifest: ApplicationData = {
  settings: {
    pageTitle: 'Do-it done',
    baseURL: 'https://doit-done.com',
    language: 'en',
    widgets: {
      main: {
        bookmarks: {
          shouldShow: false,
          name: 'Bookmarks'
        },
        popularSites: {
          shouldShow: true,
          name: 'PopularSite',
          url: '/tiles.json',
          hasExpirationDate: true
        },
        search: {
          shouldShow: true,
          name: 'Search panel',
          sites: [
            {
              name: 'yahoo',
              icon: './assets/yahoo.png',
              url: '/search/rd?sid=doitd&stype=yahoo&&src=nt&q='
            }
          ],
          default: 'yahoo'
        },
        date: {
          name: 'Date and Time',
          shouldShow: true,
          hour12: true
        }
      },
      panel: {
        // weather: {
        //   name: 'Weather',
        //   locationPanel: 'left',
        //   unit: 'metric',
        //   shouldShow: true
        // },
        // stocks: {
        //   name: 'Stock Market',
        //   shouldShow: true,
        //   symbols: ['SPY', 'QQQ', 'VNQ', 'DVY', 'SCHD'],
        //   locationPanel: 'right'
        // },
        quotes: {
          name: 'Quotes',
          shouldShow: true,
          locationPanel: 'right',
          fontFamily: 'Arial'
        },
        // news: {
        //   shouldShow: true,
        //   name: 'News feed',
        //   locationPanel: 'right',
        //   categories: [
        //     { key: 'business', name: 'Business' },
        //     { key: 'entertainment', name: 'Entertainment' },
        //     { key: 'politics', name: 'Politics' },
        //     { key: 'scienceAndTechnology', name: 'Science and Technology' },
        //     { key: 'sports', name: 'Sports' },
        //     { key: 'world', name: 'World' },
        //     { key: 'all', name: 'All' }
        //   ],
        //   selectedCategory: 'business',
        //   shouldEnableCategoryChange: false
        // },
        todo: {
          name: 'Todo',
          shouldShow: true,
          locationPanel: 'right'
        },
        notes: {
          name: 'Notes',
          shouldShow: true,
          locationPanel: 'left'
        }
      }
    },
    backgroundImages: [
      { url: '/backgroundImages/lightMode2.jpg', themeMode: 'light', isDefault: true },
      { url: '/backgroundImages/lightMode.jpg', themeMode: 'light', isDefault: false }
    ],
    policies: {
      items:[
        {
          text: 'Privacy Policy',
          href: 'https://doit-done.com/privacy-policy'
        },
        {
          text: 'Terms of Use',
          href: 'https://doit-done.com/terms'
        },
        {
          text: 'Uninstall',
          href: 'https://doit-done.com/howtouninstall'
        }
      ],
      location: 'left',
      color: 'black'
    },
    isCrx: true
  }
};